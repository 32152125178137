import React from 'react';

import SimplePageLayout from '../components/SimplePageLayout';
import {Helmet} from "react-helmet";

export const SonyPageTemplate = () => {

  return(
    <main role="main" className="sony-page">
      <Helmet>
        <title>PeteHealth</title>
      </Helmet>
      {/* Begin Banner */}
        <div className="mb-0 banner-wrapper" style={{"backgroundImage": "linear-gradient(90deg, rgba(0, 35, 78, 0.85), rgba(0, 35, 78, 0.85), rgba(0, 35, 78, 0.85)), url(/assets/newimg/fortherapistbanner.png)"}}>
          <div className="container">
            <div className="row flex-column flex-nowrap">
              <div className="col-lg-12 pt-6 pb-7">
                <a className="my-0 mr-md-auto" href="https://calendly.com/ted_petehealth/10min" target="_blank"><img src="/assets/img/logo_white.svg" alt="" className="logo" /></a>
                <h1 className="text-white title">Book a free assessment <br/>with Ted to <span style={{fontWeight: 'bold'}}>optimize <br/> your health.</span></h1>
              </div>
            </div>
          </div>
        </div>
      {/* End Banner */}
      <div className="founder pt-6 pb-7">
        <div className="container">
          <div className="d-flex flex-nowrap founder-wrapper">
            <div className="founder-img">
              <img src="/assets/newimg/trainer1.png" alt=""/>
            </div>
            <div className="founder-info">
              <h2 className="mb-0">Ted Steben, MPT</h2>
              <h3>Physical therapist and co-founder of PeteHealth</h3>
              <p>For 17 years Ted Steben's mission has been to provide the best outpatient physical therapy care for his patients. Ted incorporates manual treatment along with specialized therapeutic exercise to effectively treat a wide range of orthopedic injuries and post-surgical cases. With PeteHealth, he is able to treat patients in their home, at their work, or through teletherapy sessions all while using the patient's medical insurance.</p>
              <a href="https://calendly.com/ted_petehealth/10min" target="_blank" className="btn btn-gr pl-3 pr-3 visit-btn fw-6">Book a free assessment</a>
            </div>
          </div>
        </div>
      </div>

      <div className="sony-easy d-flex flex-nowrap">
            <div className="sony-easy-left col-lg-6">
              <img className="sony-easy-img" src="/assets/img/logo_white.svg" alt="logo"/>
              <p className="description text-white mb-0">
                PeteHealth is offering free <br/> consults via video conference <br/>for Athletic Club members.
                <br/>
                <br/>
                <a className="text-white" href="https://calendly.com/ted_petehealth/10min" target="_blank">Schedule an appointment</a> with Ted to find out more.
              </p>
            </div>
            <div className="sony-easy-right d-flex flex-column justify-content-center col-lg-6">
                <h2>What others are saying about Pete</h2>
                <div className="comments-wrapper d-flex justify-content-between">
                  <div className="comment-item d-flex justify-content-between flex-column">
                    <p className="comment">What an amazing discovery for physical therapy! I had rotator cuff surgery, and was struggling to find appointmentS at other places, or any continuity for my post care. Pete Health comes to me, and it's literally the best PT I've ever had!
                    </p>
                    <div className="comment-info d-flex justify-content-between align-items-center">
                      <p className="comment--author">Daryl P.</p>
                      <img className="comment--star" src="/assets/img/review_star.png" alt="star"/>
                    </div>
                  </div>
                  <div className="comment-item d-flex justify-content-between flex-column">
                    <p className="comment">I can't say enough good things.
                      I love that my therapist emails me videos of the stretches and exercises to do in between sessions and on my own. I recommend Pete Health very highly to anyone experiencing back and sciatica pain.
                    </p>
                    <div className="comment-info d-flex justify-content-between align-items-center">
                      <p className="comment--author">Karen A.</p>
                      <img className="comment--star" src="/assets/img/review_star.png" alt="star"/>
                    </div>
                  </div>
                </div>
            </div>
      </div>

      <div className="sony-image-block" style={{"backgroundImage": `url(/assets/newimg/landingbanner.png)`}}></div>

    </main>
  );
}

const SonyPage = () => {
  return (
    <SimplePageLayout>
      <SonyPageTemplate />
    </SimplePageLayout>
  )
};


export default SonyPage;
